<template>
    <!-- 导航 -->
    <div class="box" :style="{backgroundImage: `url(${personList.length == 0 ? $Config.UI_CONFIG.loginFaceBg : $Config.UI_CONFIG.loginBg})`}">
        <page-head/>

        <div class="face" v-show="personList.length == 0">
            <div class="faceVideo">
                <div class="faceBg" :style="{backgroundImage: `url(${$Config.UI_CONFIG.loginFaceBorder})`}"></div>
                <video ref="inputVideo"
                       id="inputVideo"
                       muted
                       @play="hasUser = true">
                </video>
                <canvas ref="canvasDom" id="canvasDom"/>
            </div>
            <div class="faceAnimate" v-show="personList.length == 0">
                <div class="progress"></div>
                <div class="border" :style="{backgroundImage: `url(${$Config.UI_CONFIG.loginFaceBorder2})`}"></div>
                <div class="animate" :style="{backgroundImage: `url(${$Config.UI_CONFIG.loginFaceBorder3})`}"></div>
            </div>
        </div>

        <div v-if="personList.length">
            <div class="userItem" v-for="(item, index) in personList" :key="item.id"
                 :class="selectedId === item.id ? 'big' : ''"
                 @click="loginFn(item)">
                <img :src="$Config.UI_CONFIG.loginUserBg" alt="" class="userBgImg">
                <div class="userBg">
                    <!--                                        <span class="loginImg"><img src="@i/uiConfig/bossImg.png" alt=""></span>-->
                    <span class="loginImg"><img :src="item.avatar" alt=""></span>
                </div>
                <span class="loginName">
                        <i class="name">{{ item.name }}</i>
                        <i class="position">{{ item.job }}</i>
                    <!--                        <i class="position PingFang">中赫北控足球俱乐部总经理</i>-->
                </span>
                <span class="indexNum">{{ index + 1 }}</span>

            </div>
        </div>

    </div>
</template>


<script>
// @ is an alias to /src
import {defineComponent, toRefs, reactive, getCurrentInstance, ref, onMounted, computed, watch} from 'vue';
import {useRouter, useRoute} from 'vue-router'
import {useStore} from "vuex";

export default defineComponent({
    name: 'Home',
    components: {},
    setup() {
        const router = useRouter()
        const {proxy} = getCurrentInstance()
        const store = useStore()

        const state = reactive({
            mtcnnForwardParams: {},
            stream: null,
            getUserMediaFail: false,

            canvasDom: null,
            videoDom: null,
            videoWidth: 1015,
            videoHeight: 700,

            isOpenFace: false, // 是否开启识别
            hasUser: false, // 画面内是否有人
            faceList: [], // 模型识别出的人脸数组
            personList: [
                {
                    address: "",
                    avatar: "https://acme-rfbc.oss-cn-beijing.aliyuncs.com/files/7a379e3b-4635-4e9e-b5d8-5075829b4614",
                    bankAccountNumber: "",
                    birthday: "",
                    bloodType: "",
                    bottomSize: "",
                    cleatsSize: "",
                    coatSize: "",
                    country: "cn",
                    deleted: false,
                    email: "zhangkaiyue@acme-ai.com",
                    entryDate: "",
                    formerClub: "",
                    height: "",
                    hidden: true,
                    id: "6108cd871f346c6a1685cafd",
                    identityCard: "",
                    jobId: "6295b97057552c62a3acb2b3",
                    language: "zh",
                    name: "张凯月",
                    nameEn: "zhangkaiyue",
                    nationality: "",
                    passport: "",
                    phone: "17600242179",
                    priority: 2,
                    registeredResidence: "",
                    role: "admin",
                    roleId: "6295b97057552c62a3acb2b7",
                    sneakerSize: "",
                    title: "headCoach",
                    token: "eyJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7ImlkIjoiNjEwOGNkODcxZjM0NmM2YTE2ODVjYWZkIiwidHlwZSI6InN0YWZmIiwiZW1haWwiOiJ6aGFuZ2thaXl1ZUBhY21lLWFpLmNvbSJ9LCJleHAiOjE2NjU2MjQyMTl9.54aEhXqfM9RAVs5IfY199D3i5homl777dlL7AHGkAg8",
                    type: "staff",
                    weight: "",
                    weixin: "",
                },
                {
                    address: "",
                    avatar: "https://acme-rfbc.oss-cn-beijing.aliyuncs.com/files/7a379e3b-4635-4e9e-b5d8-5075829b4614",
                    bankAccountNumber: "",
                    birthday: "",
                    bloodType: "",
                    bottomSize: "",
                    cleatsSize: "",
                    coatSize: "",
                    country: "cn",
                    deleted: false,
                    email: "zhangkaiyue@acme-ai.com",
                    entryDate: "",
                    formerClub: "",
                    height: "",
                    hidden: true,
                    id: "6108cd871f346c6a1685cafd2",
                    identityCard: "",
                    jobId: "6295b97057552c62a3acb2b3",
                    language: "zh",
                    name: "张凯月2",
                    nameEn: "zhangkaiyue",
                    nationality: "",
                    passport: "",
                    phone: "17600242179",
                    priority: 2,
                    registeredResidence: "",
                    role: "admin",
                    roleId: "6295b97057552c62a3acb2b7",
                    sneakerSize: "",
                    title: "headCoach",
                    token: "eyJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7ImlkIjoiNjEwOGNkODcxZjM0NmM2YTE2ODVjYWZkIiwidHlwZSI6InN0YWZmIiwiZW1haWwiOiJ6aGFuZ2thaXl1ZUBhY21lLWFpLmNvbSJ9LCJleHAiOjE2NjU2MjQyMTl9.54aEhXqfM9RAVs5IfY199D3i5homl777dlL7AHGkAg8",
                    type: "staff",
                    weight: "",
                    weixin: "",
                },
                {
                    address: "",
                    avatar: "https://acme-rfbc.oss-cn-beijing.aliyuncs.com/files/7a379e3b-4635-4e9e-b5d8-5075829b4614",
                    bankAccountNumber: "",
                    birthday: "",
                    bloodType: "",
                    bottomSize: "",
                    cleatsSize: "",
                    coatSize: "",
                    country: "cn",
                    deleted: false,
                    email: "zhangkaiyue@acme-ai.com",
                    entryDate: "",
                    formerClub: "",
                    height: "",
                    hidden: true,
                    id: "6108cd871f346c6a1685cafd3",
                    identityCard: "",
                    jobId: "6295b97057552c62a3acb2b3",
                    language: "zh",
                    name: "张凯月3",
                    nameEn: "zhangkaiyue",
                    nationality: "",
                    passport: "",
                    phone: "17600242179",
                    priority: 2,
                    registeredResidence: "",
                    role: "admin",
                    roleId: "6295b97057552c62a3acb2b7",
                    sneakerSize: "",
                    title: "headCoach",
                    token: "eyJhbGciOiJIUzI1NiJ9.eyJkYXRhIjp7ImlkIjoiNjEwOGNkODcxZjM0NmM2YTE2ODVjYWZkIiwidHlwZSI6InN0YWZmIiwiZW1haWwiOiJ6aGFuZ2thaXl1ZUBhY21lLWFpLmNvbSJ9LCJleHAiOjE2NjU2MjQyMTl9.54aEhXqfM9RAVs5IfY199D3i5homl777dlL7AHGkAg8",
                    type: "staff",
                    weight: "",
                    weixin: "",
                },
            ], // 接口请求出的用户
            selectedId: '', // 选中登录的用户id
            faceNum: 0, // 识别的次数 （给后端发送截图的次数）5

            timer: null, // 定时器
        });

        state.mtcnnForwardParams = new proxy.MtcnnOptions({
            minFaceSize: 20
        })


        onMounted(() => {

            state.canvasDom = document.getElementById("canvasDom");
            state.videoDom = document.getElementById("inputVideo");
            getCommonData();

            state.personList = [];

            proxy.$emit('openRtc')

            handleStopVideo();
            playMedia();

        })


        watch(
            () => state.hasUser,
            (val) => {
                if (val) {
                    setImage();
                }
            }
        )
        watch(
            () => state.personList,
            (val) => {
                if (val.length) {
                    // 如果只有一个登录用户 3秒后自动登录
                    if (val.length === 1) {
                        // 播放正在登陆的话术
                        proxy.$utils.playVoiceFn(proxy.$aiJson.loginSuccess)

                        clearTimeout(state.timer)
                        state.timer = setTimeout(() => {
                            console.log('跳转页面');
                            loginFn(val[0])
                        }, 3000)
                    } else {
                        // 播放让用户选择登录的话术
                        proxy.$utils.playVoiceFn(proxy.$aiJson.loginSelect);
                    }
                }
            }
        )

        watch(() => store.state.identifyData, (val, old) => {
            // console.log(val, old);
            if (!val.type) return false;
            const params = {
                ...val,
                showList: state.personList
            }
            proxy.$utils.identifyFn({
                type: val.type,
                data: params,
                changePage: () => {
                },
                router: loginFn
            })
        })
        /**
         * 公共数据集合
         */
        const getCommonData = () => {
            // 球员位置
            const params = {
                key: proxy.$Config.POSITION
            };
            proxy.$server.getCommonData(params).then((res) => {
                handleData(res, 'player');
            });
        }
        /**
         * 处理数据
         * @param oldList
         * @param type
         */
        const handleData = (oldList, type) => {
            let list = oldList.data || [];
            let objMap = {};
            list.forEach((item) => {
                objMap[item.key] = {
                    label: item.value,
                    labelEn: item.valueEn,
                };
            });
            let value = list.map((item) => {
                return {
                    value: item.key,
                    label: item.value,
                    labelEn: item.valueEn,
                };
            });
            const positionList = {
                key: `${type}PositionList`,
                value,
            };
            const positionMap = {
                key: `${type}PositionMap`,
                value: objMap,
            };
            console.log(positionList)
            store.commit('setStateData', positionMap)
            store.commit('setStateData', positionList)
        }

        /**
         * 职位中英文切换
         * @param key
         * @param lang
         * @returns {*}
         */
        const getPost = (key, lang) => {
            // console.log(key)
            // console.log(store.state.coachPositionMap)
            let item = store.state.coachPositionMap[key];
            return item ? (lang === 'en' ? item.labelEn : item.label) : key;
        }
        /**
         * 开启摄像头
         * @param success
         * @param error
         */
        const getUserMedia = (
            success,
            error,
        ) => {
            //优先使用前置摄像头（如果有的话）：{ video: { facingMode: "user" } }
            //强制使用后置摄像头：{ video: { facingMode: { exact: "environment" } } }
            // video: {
            //    width: { min: 1024, ideal: 1280, max: 1920 },
            //    height: { min: 776, ideal: 720, max: 1080 }
            // }
            //ideal（应用最理想的）值

            // 初始化
            state.personList = [];
            state.faceList = [];

            // state.videoWidth = document.body.clientWidth;
            // state.videoHeight = document.body.clientHeight;
            state.canvasDom.width = state.videoWidth;
            state.canvasDom.height = state.videoHeight;

            const constraints = {
                audio: false,
                video: {
                    facingMode: 'user',
                    width: {ideal: state.videoWidth},
                    height: {ideal: state.videoHeight},
                },
            };
            if (navigator.mediaDevices.getUserMedia) {
                // 最新的标准API
                navigator.mediaDevices
                    .getUserMedia(constraints)
                    .then(success)
                    .catch(error);
            } else if (navigator.webkitGetUserMedia) {
                // webkit核心浏览器
                navigator.webkitGetUserMedia(constraints, success, error);
            } else if (navigator.mozGetUserMedia) {
                // firfox浏览器
                navigator.mozGetUserMedia(constraints, success, error);
            } else if (navigator.getUserMedia) {
                // 旧版API
                navigator.getUserMedia(constraints, success, error);
            }
        }

        /** 开启摄像头 **/
        const playMedia = () => {
            getUserMedia(
                streams => {
                    //后续用于停止视频流
                    state.stream = streams;
                    // console.log(streams);
                    //显示视频
                    if (state.videoDom) state.videoDom['srcObject'] = streams;
                    state.videoDom.play();
                    state.isOpenFace = true;
                },
                error => (state.getUserMediaFail = true),
            )
        };

        /**
         * 开启摄像头后启动模型，识别人脸
         * @returns {Promise<void>}
         */
        const onPlayFn = async () => {
            // run face detection & recognition
            // ...

            let mtcnnResults = [];

            proxy.mtcnn(state.videoDom, state.mtcnnForwardParams)
                .then(faceArr => {
                    mtcnnResults = faceArr;
                    // console.log(mtcnnResults);
                    if (mtcnnResults.length && !state.videoDom.paused) {
                        if (!state.hasUser) {
                            state.hasUser = mtcnnResults.length > 0 ? true : false;
                        }
                        state.faceList = mtcnnResults.map(result => {
                            let percent = (result.detection.score * 100).toString().substr(0, 2);
                            let width = result.detection.box.width;
                            let height = result.detection.box.height;
                            let left = result.detection.box.x;
                            let top = result.detection.box.y;
                            return {
                                top,
                                left,
                                width,
                                height,
                                percent
                            }
                        });
                    } else {
                        state.canvasDom
                            .getContext("2d")
                            .clearRect(0, 0, state.videoWidth, state.videoHeight);
                    }

                }).catch(err => {
                console.log(err)
            });


            setTimeout(() => onPlayFn())
        }

        /**
         * 截图发送给后端
         */
        const setImage = () => {
            setTimeout(() => {
                if (!state.isOpenFace) return;
                state.canvasDom.getContext("2d").drawImage(state.videoDom, 0, 0, state.videoWidth, state.videoHeight); // 截图
                let image = state.canvasDom.toDataURL('image/png');
                // console.log(image); // base64
                let param = new FormData();
                let img = dataURLtoFile(image, 'searchFace.png')
                // console.log(img, 'img'); // 转换后文件
                param.append('face', img);
                let callback = () => {
                    state.faceNum++;
                    if (state.faceNum === 5) {
                        handleStopVideo();
                        router.push('/');
                        console.log('识别了5次，失败！')
                    } else {
                        if (state.isOpenFace) {
                            setImage();
                        }
                    }
                }

                proxy.$server.pushImgOss(param).then(res => {
                    if (res.code === 200) {
                        let data = res.data;
                        if (data.length === 0) {
                            callback();
                        } else {
                            handleStopVideo();
                            proxy.$emit('openRtc')
                            // 最多只取三个用户
                            state.personList = data.slice(0, 3);
                        }
                    } else {
                        handleStopVideo();
                        router.push('/');
                    }
                }).catch(() => {
                    callback();
                })

                state.canvasDom
                    .getContext("2d")
                    .clearRect(0, 0, state.videoWidth, state.videoHeight);
            }, 3000);
        }

        /**@function  base64转文件
         *****************************************/
        const dataURLtoFile = (dataUrl, filename) => {
            let arr = dataUrl.split(',')
            let mime = arr[0].match(/:(.*?);/)[1]
            let bstr = atob(arr[1])
            let n = bstr.length
            let u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            return new File([u8arr], filename, {
                type: mime
            })
        }


        /** @name 停止video */
        const handleStopVideo = () => {
            state.canvasDom
                .getContext("2d")
                .clearRect(0, 0, state.videoWidth, state.videoHeight);
            state.stream?.getTracks().forEach((track) => track.stop());
            state.stream = null;
            state.videoDom.srcObject = null;
            state.isOpenFace = false;
            state.hasUser = false;
            state.videoDom.pause();
            setTimeout(() => {
                state.faceList = [];
            }, 500)
        }

        const loginFn = (user) => {
            // 获取选中的用户取token

            console.log(user)
            let params = {
                face_id: user.faceId
            }
            proxy.$server.getToken(params)
                .then(res => {
                    state.selectedId = user.id;
                    store.commit('setToken', res.pass)
                    store.commit('setUserData', user)
                    clearTimeout(state.timer);
                    router.replace('/organization/index')
                })

        }

        return {
            ...toRefs(state),

            onPlayFn,
            playMedia,
            handleStopVideo,
            loginFn,
            getPost
        }
    },
})
</script>

<style lang="scss" scoped>
::v-deep .navigation-mian {
    position: absolute;
    left: 0;
    top: 0;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: 100% 100%;
    background-position: center;


    .face {
        width: 676px;
        height: 676px;
        position: relative;
        box-sizing: border-box;
        top: -25px;
    }

    .faceVideo {
        width: 565px;
        height: 565px;
        position: relative;
        overflow: hidden;
        left: calc((676px - 565px) / 2);
        top: calc((676px - 565px) / 2);
        border-radius: 488px;

        .faceBg {
            background-size: 100%;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 1000;
        }


        video, canvas, .face_box {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }

        video {
            width: auto;
            height: 100%;
            transform: translate(-15%);
        }

    }

    .faceAnimate {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 100000000;

        .progress {
            position: absolute;
            bottom: 0;
            left: calc((100% - 614px) / 2);
            width: 614px;
            height: 160px;
            display: block;
            animation: progressAni 5s infinite linear;
            background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
            opacity: 0.4;
            border-radius: 3px;
            z-index: 100000000;
        }

        .border {
            transform: scale(1.07);
            top: -7px;
            animation: borderAni 8s infinite linear;
        }

        .border, .animate {
            position: absolute;
            width: 100%;
            height: 100%;
            background-size: 100%;
        }
    }


    .userItem {
        width: 515.29px;
        height: 755.8px;
        position: relative;
        transition: all 0.6s; /*所有属性变化在0.6秒内执行动画*/
        display: inline-block;

        &:hover {
            transform: scale(1.2); /*鼠标放上之后元素变成1.2倍大小*/
        }

        .userBgImg, .userBg {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 10;
            width: 100%;
        }

        .userBg {
            height: 724px;
            width: 365px;
            z-index: 9;
            bottom: 6px;
            top: auto;
            left: 65px;
            background: #000000;
            box-shadow: 20px 30px 40px rgba(0, 0, 0, 0.4), -30px 10px 40px rgba(124, 56, 254, 0.4);
            transform: skewX(-9deg);
            overflow: hidden;
        }

        .loginImg {
            display: inline-block;
            height: 724px;
            width: 365px;
            z-index: 11;
            transform: skewX(9deg);

            img {
                position: absolute;
                bottom: 20px;
                left: -100px;
                height: 75%;
            }
        }

        .loginName {
            position: absolute;
            left: 30px;
            bottom: 30px;
            z-index: 300;
            width: 100%;
            text-align: left;

            i {
                font-style: normal;
                display: block;
                color: #FFFFFF;
            }

            .name {
                font-size: 36px;
            }

            .position {
                padding-top: 20px;
                font-size: 22px;
            }
        }

        .indexNum {
            font-size: 80px;
            line-height: 112px;
            right: 167px;
            bottom: 46px;
            position: absolute;
            z-index: 11;
            color: #fff;
            font-family: PingFangMedium;
        }
    }
}

@keyframes progressAni {
    0% {
        top: 30px;
    }
    50% {
        top: calc(100% - 120px);
    }
    100% {
        top: 30px;
    }
}

@keyframes borderAni {
    0% {
        transform: scale(1.07) rotate(0deg);
    }
    50% {
        transform: scale(1.07) rotate(180deg) ;
    }

    100% {
        transform: scale(1.07) rotate(360deg);
    }
}
</style>